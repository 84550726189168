import request from '@/utils/request'
import constant from '@/constant'

function accountInformation(){
    return request.post(constant.serverUrl + "/merchantClient/home/accountInformation");
}

function revenueBriefing(formData){
    return request.post(constant.serverUrl + "/merchantClient/home/revenueBriefing",formData);
}

function goodsSalesStatistics(formData){
    return request.post(constant.serverUrl + "/merchantClient/home/goodsSalesStatistics",formData);
}

function revenueTrend(formData){
    return request.post(constant.serverUrl + "/merchantClient/home/revenueTrend",formData);
}





export default {
    accountInformation,revenueBriefing,goodsSalesStatistics,revenueTrend
}