<style scoped>
.user-panel {
  margin: 10px auto;
}
input{
    border: 1px solid;
}
</style>
<template>
  <el-dialog
        width="30%"
        :visible.sync="showDialog"
        :title="title"
        :modal-append-to-body="true"
        :append-to-body="true"
        style="text-align:left;"
        @close="closeDialog"
        :close-on-click-modal="false"
        >
            <div class="user-panel" v-loading="loading">
                <el-form ref="form" :model="formModel"  :rules="ruleValidate">
                    <el-form-item label="商家名称" prop="merchantName" label-width="100px">
                        <el-input v-model="formModel.merchantName" placeholder="请输入商家名称" style="width:300px" ></el-input>
                    </el-form-item>
                    <el-form-item label="联系人" prop="contactName" label-width="100px">
                        <el-input v-model="formModel.contactName" placeholder="请输入联系人" style="width:300px"></el-input>
                    </el-form-item>
                    <el-form-item label="联系方式" prop="contactPhone" label-width="100px">
                        <el-input v-model="formModel.contactPhone" placeholder="请输入联系方式" style="width:300px"></el-input>
                    </el-form-item>
                    <el-form-item label="可提现金" prop="withdrawalTotal" label-width="100px">
                        <el-input v-model="formModel.withdrawalTotal" placeholder="请输入线路名称" style="width:300px"></el-input>
                    </el-form-item>
                    <el-form-item label="提现金额" prop="applicationFee" label-width="100px">
                        <el-input v-model="formModel.applicationFee" placeholder="请输入提现金额" style="width:300px"></el-input>
                    </el-form-item>
                </el-form>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button @click="closeDialog">取 消</el-button>
                <el-button type="primary" @click="handleSubmit"  :loading="submitting">确 定</el-button>
            </span>
  </el-dialog>
</template>
<script>
import Constant from "@/constant";
import withdrawalApplicationApi from '@/api/merchantClient/withdrawalApplication'
export default {
    props: ["businessKey", "title"],
    data() {
        let validateFee = (rule, value, callback) => {
            var withdrawalTotal = this.formModel.withdrawalTotal;
            var applicationFee = this.formModel.applicationFee;

            if(applicationFee>withdrawalTotal){
                return callback(new Error('提现金额不能大于可提现金金额！'))
            }
            else{
                 return callback()
            }
        };
        return{
            formModel:{
                id: "",
                merchantName:"",
                contactName:"",
                contactPhone:"",
                withdrawalTotal:"",
                applicationFee:""
            },
            ruleValidate: {
                applicationFee: [{ required: true, message: "提现金额不能为空", trigger: "blur" },
                { validator: validateFee, trigger: 'blur'}],
            },
            showDialog: true,
            loading: false,
            submitting: false,
        }
    },
    methods:{
        closeDialog() {
            this.$emit("close", false);
        },

        handleSubmit() {
            var self = this;

            this.$refs["form"].validate(valid => {
                if (valid) {          
                self.loading = true;

                (function() {
                    return withdrawalApplicationApi.add(self.formModel);
                })().then(function(response) {
                    self.loading = false;
                    var jsonData = response.data;

                    if (jsonData.result) {
                    self.$message({
                        message: "保存成功!",
                        type: "success"
                    });
                    self.$emit("close", {
                        result: true,
                        data: jsonData.data
                    });
                    } else {
                    self.$message({
                        message: jsonData.message + "",
                        type: "warning"
                    });

                    self.$emit("close", {
                        result: false
                    });
                    }
                });
                }
            });
        },
    },
    async mounted() {
        console.log("mounted");

        var self = this;

        (function() {
            return withdrawalApplicationApi.create();
        })()
        .then(response => {
            var jsonData = response.data;

            if (jsonData.result) {
                self.formModel = jsonData.data;
            } else {
                self.$message.error(jsonData.message + "");
            }
        })
        .catch(error => {
            self.$message.error(error + "");
        });
    }
}
</script>
