<template>
    <div class="main">
        <div class="fyy-source">
            <div class="fyy-index-title">
            <h5>营收统计</h5>
            <el-button  @click="handleAdd">申请提现</el-button>
            </div>
            <el-row :gutter="20">
            <el-col :span="6">
                <div class="grid-content bg-purple-ora"> <img src="../../assets/merchantClient/img/index01.png"/>
                <div class="fyy-toptxt">
                    <h5>总收益金额</h5>
                    <h3>{{total}}</h3>
                </div>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="grid-content bg-purple-blu"> <img src="../../assets/merchantClient/img/index02.png"/>
                <div class="fyy-toptxt">
                    <h5>可提现金额</h5>
                    <h3>{{withdrawalTotal}}</h3>
                </div>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="grid-content bg-purple-gre"> <img src="../../assets/merchantClient/img/index03.png"/>
                <div class="fyy-toptxt">
                    <h5>即将解冻金额</h5>
                    <h3>{{soonThawTotal}}</h3>
                </div>
                </div>
            </el-col>
            <el-col :span="6">
                <div class="grid-content bg-purple-lue"> <img src="../../assets/merchantClient/img/index04.png"/>
                <div class="fyy-toptxt">
                    <h5>累计解冻金额</h5>
                    <h3>{{thawTotal}}</h3>
                </div>
                </div>
            </el-col>
            </el-row>
        </div>
        <el-divider></el-divider>

        <el-form ref="queryForm" :model="queryModel" inline class="demo-form-inline">
            <el-form-item label="申请单号" prop="applicationNo">
                <el-input v-model="queryModel.applicationNo" size="mini"/>
            </el-form-item>
            <el-form-item label="申请时间" prop="applicationTimeRange">
                <el-date-picker
                    size="mini"
                    v-model="queryModel.applicationTimeRange"
                    type="daterange"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    value-format="yyyy-MM-dd"
                ></el-date-picker>
            </el-form-item>
            <el-form-item label="申请状态" prop="status">
                <el-select v-model="queryModel.status" placeholder="请选择" size="mini">
                    <el-option
                        v-for="item in statusList"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                    ></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button
                type="primary"
                size="mini"
                icon="ios-search"
                @click="changePage(1)"
                :loading="loading"
                >查询</el-button>&nbsp;
                <el-button
                    type="info"
                    size="mini"
                    style="margin-left: 8px"
                    @click="handleReset('queryForm')"
                >重置</el-button>
            </el-form-item>
        </el-form>
        <el-divider></el-divider>
        <!-- <el-row class="button-group">
        <el-button
            type="primary"
            size="small"
            plain
            icon="el-icon-download"
            :loading="downloadLoading"
            @click="downloadXls"
        >导出数据</el-button>
        </el-row> -->
        <el-table
            ref="formTable"
            :data="tableData"
            v-loading="loading"
            element-loading-text="拼命加载中"
            stripe
            @selection-change="handleSelectionChange"
        >
            <el-table-column type="selection" width="55"></el-table-column>
            <el-table-column prop="applicationTime" label="申请时间" fixed="left"></el-table-column>
            <el-table-column prop="applicationNo" label="申请单号" fixed="left"></el-table-column>
            <el-table-column prop="createBy" label="申请人" fixed="left"></el-table-column>
            <el-table-column prop="merchantName" label="商家名称" fixed="left"></el-table-column>
            <el-table-column prop="contactPhone" label="联系方式" fixed="left"></el-table-column>
            <el-table-column prop="applicationFee" label="申请提现金额" fixed="left"></el-table-column>
            <el-table-column prop="status" label="申请单状态" fixed="left"></el-table-column>
            <el-table-column prop="transferTime" label="转账时间" fixed="left"></el-table-column>
            <el-table-column prop="transferVoucher" label="转账凭证" >
                <template slot-scope="{row}">
                    <a v-if="row.transferVoucher" :href="row.transferVoucher" target="_blank">
                        <el-image
                        :size="50"
                        :src="row.transferVoucher+'?x-oss-process=image/resize,m_fill,w_64,h_64'"
                        :key="row.id"
                        ></el-image>
                    </a>
                </template>
            </el-table-column>
        </el-table>
        <div style="text-align:center;">
            <el-pagination
                :current-page.sync="pageIndex"
                :total="totalElements"
                :page-sizes="pageSizeList"
                @current-change="changePage"
                @size-change="pageSizeChange"
                layout="total, sizes, prev, pager, next,  jumper"
            ></el-pagination>
        </div>
        <withdrawalApplication
        v-if="showModal"
        :title="modalTitle"
        :businessKey="businessKey"
        @close="onDetailModalClose"
        ></withdrawalApplication>
    </div>
</template>
<script>
import homeApi from '@/api/merchantClient/home'
import merchantApplicationRecordApi from '@/api/merchant/merchantApplicationRecord'
import merchantInfoApi from '@/api/merchant/merchantInfo'
import withdrawalApplication from "./withdrawalApplication";
import withdrawalApplicationApi from '@/api/merchantClient/withdrawalApplication'

export default {
    name:"merchantClientMerchantApplicationRecordList",
    data(){
         return{
            queryModel:{
                applicationNo:"",
                merchantId:"",
                applicationTimeRange:"",
                status:"",
                transferTimeRange:"",
            },
            statusList:[{
                label:"已提交",
                value:"1"
            },{
                label:"已转账",
                value:"2"
            }],
            merchantList:[],
            loading: false,
            tableData: [],
            multipleSelection: [],
            pageIndex: 1,
            pageSize: 10,
            totalElements: 0,
            pageSizeList: [10,20,30],
            showModal: false,
            total:"",
            withdrawalTotal:"",
            soonThawTotal:"",
            thawTotal:"",
         }
    },
    methods: {
        changePage(pageIndex) {
            this.loading = true;
            var formData = new FormData();

            formData.append("pageIndex", this.pageIndex);
            formData.append("pageSize", this.pageSize);
            formData.append("applicationNo", this.queryModel.applicationNo);
            formData.append("merchantId", this.queryModel.merchantId);
            formData.append("applicationTimeRange", this.queryModel.applicationTimeRange);
            formData.append("status", this.queryModel.status);
            formData.append("transferTimeRange", this.queryModel.transferTimeRange);


            withdrawalApplicationApi.pageList(formData).then(response => {
                this.loading = false;
                var jsonData = response.data;

                console.log(jsonData);

                this.tableData = jsonData.data.data;
                this.totalElements = jsonData.data.recordsTotal;

                this.pageIndex = pageIndex;
            });
        },
        pageSizeChange(pageSize) {
            this.pageSize = pageSize;
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        handleReset(name) {
            this.$refs[name].resetFields();
        },
        loopDelete(list, id) {
            var rs = false;

            for (var i = 0; i < list.length; i++) {
                if (list[i].id == id) {
                list.splice(i, 1);
                rs = true;
                break;
                }

                if (list[i].children != null) {
                rs = this.loopDelete(list[i].children, id);

                if (rs) {
                    break;
                }
                }
            }

            return rs;
        },
        handleAdd() {
            this.modalTitle = "请填写提现申请单";
            this.businessKey = "";
            this.showModal = true;
        },
        onDetailModalClose(refreshed) {
            //保存成功后回调
            this.showModal = false;

            if (refreshed) {
                this.changePage(this.pageIndex);
            }
        },
    },
    components: {
        "withdrawalApplication": withdrawalApplication
    },
    mounted() {
        this.changePage(1);

        var self = this;

        merchantInfoApi.list().then(function(response) {
            var jsonData = response.data;

            if (jsonData.result) {
                self.merchantList = jsonData.data;
            }
        });

        homeApi.accountInformation().then((resp)=>{
            var jsonData = resp.data;
            
            if (jsonData.result) {
               this.total = jsonData.data.total;
               this.withdrawalTotal=jsonData.data.withdrawalTotal;
               this.soonThawTotal=jsonData.data.soonThawTotal;
               this.thawTotal=jsonData.data.thawTotal;
            }
        });
    },
}
</script>
<style lang="scss" scoped src="../../assets/merchantClient/css/admin.scss"></style>
<style scoped>
.merchantApplicationRecord-list{
    text-align: left;
}
.el-breadcrumb {
    margin: 10px;
    line-height: 20px;
}
</style>