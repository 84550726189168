import request from '@/utils/request'
import constant from '@/constant'

function create(){
    return request.get(constant.serverUrl + "/merchantClient/withdrawalApplication/create");
}


function add(formModel){
    return request.post(constant.serverUrl + "/merchantClient/withdrawalApplication/add", formModel,{
        headers: {
          "Content-Type": "application/json"
        }
    });
}


function pageList(formData){
    return request.post(constant.serverUrl + "/merchantClient/withdrawalApplication/pageList", formData);
}

export default {
    create,add,pageList
}