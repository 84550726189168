import request from '@/utils/request'
import constant from '@/constant'

function edit(id){
    return request.get(constant.serverUrl + "/merchant/merchantApplicationRecord/edit/" + id);
}

function update(formModel){  
    return request.post(constant.serverUrl + "/merchant/merchantApplicationRecord/update", formModel,{
      headers: {
        "Content-Type": "application/json"
      }
    });
}


function pageList(formData){
    return request.post(constant.serverUrl + "/merchant/merchantApplicationRecord/pageList", formData);
}

function exportXls(formData) {
    //导出xls
    return request.post(constant.serverUrl + "/merchant/merchantApplicationRecord/exportXls", formData);
}

export default {
    pageList,edit,update,exportXls
}